import styled from "@emotion/styled";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { useState } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

interface PDFViewerProps {
  pdfBlob: Blob;
  open: boolean;
  handleClose: () => void;
}

const PDFViewer: React.FC<PDFViewerProps> = (props: PDFViewerProps) => {
  const [numPages, setNumPages] = useState<number | null>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} fullWidth maxWidth="lg" scroll="paper">
      <IconButton
        edge="end"
        color="inherit"
        onClick={props.handleClose}
        aria-label="close"
        style={{ position: "absolute", right: 30, top: 10 }}
      >
        X
      </IconButton>
      <DialogTitle>manual</DialogTitle>

      <DialogContent>
        <StyledDocument file={props.pdfBlob} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderAnnotationLayer={false}
              renderMode="canvas"
              scale={1.5}
            />
          ))}
        </StyledDocument>
      </DialogContent>
    </Dialog>
  );
};

export default PDFViewer;

const StyledDocument = styled(Document)(() => ({
  width: "100%",
  " .react-pdf__Page__canvas": {
    width: "100% !important",
    height: "100% !important",
  },
}));
